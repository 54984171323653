<template >
  <ion-page>
    <Header
      type="0"
      title="问题记录详情"
       v-if="menuRole=='phone'"
    >
    </Header>

    <ion-content scroll-y="true" class="content">
      <div class="root">
        <div class="item" >
          <div class="left">试制任务单号</div>
          <div class="right">
            <ion-text>
              {{ formData.stageCode }}
            </ion-text>
          </div>
        </div>
        <div class="item" >
          <div class="left">设计车型号</div>
          <div class="right">
            <ion-text>
              {{ formData.truckModel }}
            </ion-text>
          </div>
        </div>
       <div class="item" >
          <div class="left">设计序号</div>
          <div class="right">
            <ion-text>
              {{ formData.designNumber }}
            </ion-text>
          </div>
        </div>
         <div class="item" >
          <div class="left">车辆编号</div>
          <div class="right">
            <ion-text>
              {{ formData.vehicleCode }}
            </ion-text>
          </div>
        </div>
         <div class="item">
          <div class="item remark">
            <ion-text class="left">
             问题描述</ion-text
            >
           <div style="max-height:150px;width:100%;overflow:auto">
              <ion-textarea
              auto-grow="true"
              class="right"
              placeholder="请输入您的描述..."
              maxlength="500"
              disabled
              v-model="formData.problemDescribe"
            ></ion-textarea>
           </div>
          </div>
        </div>
           <div class="item">
          <div class="item remark">
            <ion-text class="left">
             临时解决措施</ion-text
            >
           <div style="max-height:150px;width:100%;overflow:auto">
              <ion-textarea
              auto-grow="true"
              class="right"
              maxlength="500"
              disabled
              v-model="formData.tempMeasures"
            ></ion-textarea>
           </div>
          </div>
        </div>
           <div class="item">
          <div class="item remark">
            <ion-text class="left">
             正式解决措施</ion-text
            >
           <div style="max-height:150px;width:100%;overflow:auto">
              <ion-textarea
              auto-grow="true"
              class="right"
              maxlength="500"
              disabled
              v-model="formData.formalMeasures"
            ></ion-textarea>
           </div>
          </div>
        </div>
        <div class="item remark" >
          
          <div class="left">问题照片</div>
          <div style="width: 100%">
            <upload-img
                @modelBack="modelBack"
                :maxLength="0"
                :form="formData"
                photo="problemImage"
                :id="formData.id"
                :canRemove="false"
              ></upload-img>
          </div>
        </div>
         <div class="item remark" @click="openChooseOrganizationModal">
          <div class="left">解决后照片</div>
          <div style="width: 100%">
            <upload-img
                @modelBack="modelBack"
                :maxLength="0"
                :form="formData"
                photo="solveProblemImage"
                :id="formData.id"
                :canRemove="false"
              ></upload-img>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import {getProblemInfo} from "@/api/questionManage/questionView"
import * as dd from "dingtalk-jsapi";
export default {
  name: "questionView",
  components: {
    Header,
    uploadImg,
  },
  data() {
    return {
      formData: {
        uploadImgList: [
          {
            id: 1,
            webPath:
              "https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF",
          },
          {
            id: 2,
            webPath:
              "https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF",
          },
          {
            id: 3,
            webPath:
              "https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF",
          },
          {
            id: 4,
            webPath:
              "https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF",
          },

        ],
        problemImage:[],
        solveProblemImage:[]
      },

      isDrag: false,
      menuRole:'phone'
    };
  },
  mounted(){
      this.menuRole = localStorage.getItem("menuRole");
     if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: '问题记录详情', //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
    this.getInfo()
  },
  methods: {
    getInfo(){
      getProblemInfo({id:this.$route.query.id}).then(res=>{
        var data = res.data.data
        this.formData = data.resultData.length>0?data.resultData[0]:{}
      })
    },
    transfer(type) {
      console.log(type);
      if (type.indexOf("img") > -1) {
        console.log("img");
      } else {
        this.$router.push({ path: "/question" });
      }
    },
    openChooseOrganizationModal() {},
    modelBack(data) {
      this.formData.uploadImgList = data;
      console.log(this.formData.uploadImgList, 4424232);
    },
  },
};
</script>
<style lang="scss" scoped>
// ion-item {
//   --padding-end: 12px;
//   --padding-start: 12px;
//   --background: #fff;
// }
// .required {
//   color: #ff0000;
//   margin-right: 6px;
// }
// ion-textarea {
//   --placeholder-color: #333333;
//   --placeholder-opacity: 0.5;
//   --placeholder-font-weight: bold;
//   font-size: 10px;
//   --padding-start: 10px;
//   padding-left: 10px;
// }
// ion-label {
//   font-size: 12px !important;
//   font-family: PingFang SC, PingFang SC-Regular;
//   font-weight: bold;
//   text-align: left;
//   color: #333333;
// }
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
   ion-img{
        width: 60%;
    }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
   
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}
.remark{
  font-weight: 400;
}
</style>